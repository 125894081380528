import React from 'react';
import PropTypes from 'prop-types';

import Link from '../../../../01_atoms/Link';
import Button from '../../../../01_atoms/Button';
import withIE, { IEContext } from '../../../../05_hoc/withIE';

let columnCounter = 0;

const buildNavMenuContents = (obj, onClick = () => {}) => {
  if (!obj) {
    // TODO: Catch the case. Seems like itt may happen when publish/unpublish content - wrong cache can be generated.
    return null;
  }

  if (Array.isArray(obj)) {
    columnCounter += 1;

    return (
      <div key={`nav-menu-column-${columnCounter}`} className="col">
        {obj.map((column) => buildNavMenuContents(column, onClick))}
      </div>
    );
  }
  const { contents, label, nextLink } = obj;

  if (contents === undefined) {
    return (
      <li key={`nav-menu-link-${label}`}>
        <p>
          <Link {...nextLink}>
            <a className="header-dropdown--link header-dropdown-desktop--link">
              <span onClick={onClick} onKeyPress={onClick}>
                {label}
              </span>
            </a>
          </Link>
        </p>
      </li>
    );
  }

  return (
    <React.Fragment key={`nav-menu-header-${label}`}>
      <li className="icon-list bold">
        <p>
          <strong>
            <Link {...nextLink}>
              <a className="header-dropdown--link header-dropdown--link-bold header-dropdown-desktop--link">
                <span onClick={onClick} onKeyPress={onClick}>
                  {label}
                </span>
              </a>
            </Link>
          </strong>
        </p>
      </li>
      <ul className="icon-list">{contents.map((o) => buildNavMenuContents(o, onClick))}</ul>
    </React.Fragment>
  );
};

const HeaderDropdownNavigationDesktop = ({ cta, contents, onClick }) => (
  <>
    <div className="cta">
      {cta.src && <img src={cta.src} alt={cta.alt || cta.title} loading="lazy" />}

      {cta.title && (
        <p>
          <strong>{cta.title}</strong>
        </p>
      )}

      {cta.description && <div className="small-tag">{cta.description}</div>}

      {cta.button && cta.button.nextLink && (
        <Link {...cta.button.nextLink}>
          <Button
            type={cta.button.type}
            tag="a"
            href={cta.button.nextLink.url}
            className="header-dropdown-navigation--button header-dropdown-navigation-desktop--button"
          >
            <span onClick={onClick} onKeyPress={onClick}>
              {cta.button.label}
            </span>
          </Button>
        </Link>
      )}
    </div>

    <IEContext.Consumer>
      {({ isIE, isEdge }) => (
        <div className={`row links ${isIE || isEdge ? 'ie11-compat' : ''}`}>
          {contents.map((columns) => buildNavMenuContents(columns, onClick))}
        </div>
      )}
    </IEContext.Consumer>
  </>
);

HeaderDropdownNavigationDesktop.propTypes = {
  cta: PropTypes.shape({
    src: PropTypes.string,
    title: PropTypes.string,
    alt: PropTypes.string,
    description: PropTypes.string,
    button: PropTypes.shape({
      type: PropTypes.string,
      label: PropTypes.string,
      nextLink: PropTypes.shape(),
    }),
  }).isRequired,
  contents: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        url: PropTypes.string,
      }),
    ),
  ).isRequired,
  onClick: PropTypes.func,
};

export default withIE(HeaderDropdownNavigationDesktop);
