import React from 'react';
import PropTypes from 'prop-types';
import routerEvents from 'next-router-events';

import HeaderMenu from '../../02_molecules/Header/HeaderMenu';
import HeaderDropdownNavigation from '../../02_molecules/Header/HeaderDropdown/HeaderDropdownNavigation';
import HeaderDropdownSearch from '../../02_molecules/Header/HeaderDropdown/HeaderDropdownSearch';

import styles from '../../02_molecules/Header/HeaderMenu/index.module.scss';

class Header extends React.Component {
  createdClickOutsideListener = false;

  constructor(props) {
    super(props);

    const toggled = {
      search: false,
      navigation: false,
    };

    const navigationContents = props.navigationContents ?? [];
    navigationContents.forEach((nav) => {
      toggled[`navigation-${nav.slug}`] = false;
      toggled[`dropdown-navigation-${nav.slug}`] = false;
    });

    this.state = { toggled };

    this.dropdownRef = React.createRef();
    this.headerRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleEsc = this.handleEsc.bind(this);
    this.onRouterCompleteToggle = this.onRouterCompleteToggle.bind(this);
    this.focusTopMenuItem = this.focusTopMenuItem.bind(this);
  }

  componentDidMount() {
    // The mobile menus stay toggled on page change, so force a reset.
    routerEvents.on('routeChangeComplete', this.onRouterCompleteToggle);
    document.addEventListener('keydown', this.handleEsc);
  }

  componentWillUnmount() {
    routerEvents.off('routeChangeComplete', this.onRouterCompleteToggle);
    if (this.createdClickOutsideListener) {
      document.removeEventListener('mousedown', this.handleClickOutside);
      document.removeEventListener('keydown', this.handleEsc);
      this.createdClickOutsideListener = false;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { toggled } = this.state;
    document.addEventListener('mousedown', this.handleClickOutside);
    this.createdClickOutsideListener = true;

    // Delete listener if mobile navigation or search popup is closed.
    if (
      ((prevState.toggled.search && !toggled.search) ||
        (prevState.toggled.navigation && !toggled.navigation)) &&
      this.createdClickOutsideListener
    ) {
      document.removeEventListener('mousedown', this.handleClickOutside);
      this.createdClickOutsideListener = false;
    }
  }

  focusTopMenuItem = () => {
    const { toggled } = this.state;
    // Find opened menu item to be able to focus it.
    let openedMenu;
    Object.keys(toggled).forEach((key) => {
      if (toggled[key]) {
        openedMenu = key;
      }
    });

    // Focus menu item that was opened.
    let element;
    if (openedMenu === 'search') {
      element = document.querySelector('.header-menu-desktop-search--link');
    } else {
      element = document.querySelector('a.header-menu-desktop--link.toggled');
    }
    if (element) {
      element.focus();
    }
  };

  closeAllMenus() {
    const newToggled = this.state.toggled;
    let needUpdate = false;
    Object.keys(newToggled).forEach((key) => {
      if (newToggled[key]) {
        newToggled[key] = false;
        needUpdate = true;
      }
    });

    if (needUpdate) {
      this.setState({ toggled: newToggled });
    }
  }

  /**
   * Close search if clicks outside of the header.
   */
  handleClickOutside(event) {
    const refsExist =
      this.dropdownRef && this.headerRef && this.dropdownRef.current && this.headerRef.current;

    const isClickToSearchIcon =
      (event.target &&
        event.target.classList &&
        event.target.classList.contains('header-menu-search--link')) ||
      (event.target.parentNode &&
        event.target.parentNode.classList &&
        event.target.parentNode.classList.contains('header-menu-search--link'));

    const isClickInsideDropdown = refsExist && this.dropdownRef.current.contains(event.target);

    const isClickToTopMenuItem =
      refsExist &&
      this.headerRef.current.querySelector('.header-container .header-menu .left') &&
      this.headerRef.current
        .querySelector('.header-container .header-menu .left')
        .contains(event.target);

    if (!isClickToSearchIcon && !isClickInsideDropdown && !isClickToTopMenuItem) {
      this.closeAllMenus();
    }
  }

  /**
   * Close opened menus and search bars by ESC key.
   */
  handleEsc(event) {
    if (event.keyCode == 27) {
      this.focusTopMenuItem();

      this.closeAllMenus();
    }
  }

  onRouterCompleteToggle() {
    this.closeAllMenus();
  }

  render() {
    const {
      display = '',
      navigationContents = [],
      searchContents = null,
      topLinks = [],
    } = this.props;
    const { toggled } = this.state;

    return (
      <>
        <header
          className={`site-header ${styles['site-header']} ${display} ${styles[display]}`}
          ref={this.headerRef}
        >
          <div className="d-lg-none">
            <HeaderMenu
              device="mobile"
              onMenuToggle={(toggles) => this.setState({ toggled: toggles })}
              {...this.props}
              {...this.state}
            />
          </div>

          <div className="d-none d-lg-block">
            <HeaderMenu
              device="desktop"
              onMenuToggle={(toggles) => this.setState({ toggled: toggles })}
              contents={navigationContents}
              dropdownRef={this.dropdownRef}
              {...this.props}
              {...this.state}
            />
          </div>
        </header>

        {display === 'full' && (
          <div ref={this.dropdownRef}>
            <div className="d-lg-none">
              <HeaderDropdownNavigation
                device="mobile"
                toggled={toggled.navigation}
                onMenuToggle={(navToggled) =>
                  this.setState({
                    toggled: {
                      ...toggled,
                      navigation: navToggled,
                    },
                  })
                }
                contents={navigationContents.concat(topLinks)}
              />
            </div>

            <div className="d-none d-lg-block">
              {navigationContents.map((nav) => (
                <HeaderDropdownNavigation
                  key={`dropdown-${nav.slug}`}
                  device="desktop"
                  toggled={toggled[`navigation-${nav.slug}`]}
                  focusTopMenuItem={this.focusTopMenuItem}
                  contents={nav.columns}
                  cta={nav.cta}
                />
              ))}
            </div>
            <HeaderDropdownSearch
              toggled={toggled.search}
              focusTopMenuItem={this.focusTopMenuItem}
              searchContents={{ ...searchContents }}
              onSearch={() => this.setState({ toggled: { ...toggled, search: false } })}
            />
          </div>
        )}
      </>
    );
  }
}

const propTypes = {
  display: PropTypes.string,
  isCheckoutHeader: PropTypes.bool,
  navigationContents: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      slug: PropTypes.string,
      cta: PropTypes.shape({
        src: PropTypes.string,
        label: PropTypes.string,
        button: PropTypes.shape({
          type: PropTypes.string,
          label: PropTypes.string,
        }),
      }),
      columns: PropTypes.arrayOf(
        PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            url: PropTypes.string,
          }),
        ),
      ),
    }),
  ),
  topLinks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  searchContents: PropTypes.shape({
    suggested: PropTypes.shape({
      title: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          nextLink: PropTypes.shape({
            href: PropTypes.string,
            as: PropTypes.string,
          }),
        }),
      ),
    }),
    onGotoSearch: PropTypes.func,
    inputValidators: PropTypes.arrayOf(PropTypes.func),
  }),
};

Header.propTypes = propTypes;

export { propTypes };

export default Header;
