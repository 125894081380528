import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../../01_atoms/Link';
import Button from '../../../01_atoms/Button';

const HeaderMenuMobileFull = ({
  toggled,
  onMenuToggle = () => {},
  primaryCTA = null,
  searchToggleOnClick,
}) => (
  <div className="header-menu">
    <a
      className={`icon-toggle search header-menu-search--link ${toggled.search ? 'toggled' : ''}`}
      onClick={searchToggleOnClick}
      title={toggled ? 'Close search' : 'Open search'}
      tabIndex={0}
    >
      <img
        src={
          toggled.search
            ? '/static/icons/close-header-main-new.svg'
            : '/static/icons/knowledge-hub-search.svg'
        }
        alt={toggled.search ? 'Close search' : 'Open search'}
        width={25}
        height={25}
      />
    </a>

    <a
      className={`icon-toggle navigation ${toggled.navigation ? 'toggled' : ''}`}
      onClick={() => {
        const toggledRemains = { ...toggled };

        Object.keys(toggledRemains).forEach((key) => {
          toggledRemains[key] = false;
        });
        onMenuToggle({
          ...toggledRemains,
          navigation: !toggled.navigation,
        });
      }}
      title={toggled ? 'Close menu' : 'Open menu'}
      tabIndex={0}
    >
      <img
        src={
          toggled.navigation
            ? '/static/icons/close-header-main-new.svg'
            : '/static/icons/stacked-group.svg'
        }
        alt={toggled.navigation ? 'Close menu' : 'Open menu'}
        width={25}
        height={25}
      />
    </a>

    {primaryCTA && primaryCTA.label && primaryCTA.nextLink && (
      <Link {...primaryCTA.nextLink}>
        <Button
          tag="a"
          href={primaryCTA.nextLink.url}
          className="header-menu--button header-menu-mobile--button"
        >
          Donate
        </Button>
      </Link>
    )}
  </div>
);

HeaderMenuMobileFull.propTypes = {
  toggled: PropTypes.shape({
    navigation: PropTypes.bool,
    search: PropTypes.bool,
  }).isRequired,
  onMenuToggle: PropTypes.func,
  primaryCTA: PropTypes.shape({
    label: PropTypes.string,
    nextLink: PropTypes.shape({
      url: PropTypes.string,
      href: PropTypes.string,
      as: PropTypes.string,
    }),
  }),
  searchToggleOnClick: PropTypes.func.isRequired,
};

export default HeaderMenuMobileFull;
