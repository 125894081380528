import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../../../../01_atoms/Link';
const imageURL = '/static/icons/navigation-chevron-down.svg';

class HeaderDropdownNavigationMobileAccordion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: props.opened,
    };
  }

  toggle = () => {
    this.setState((state) => ({ opened: !state.opened }));
  };

  render() {
    const { children, label, nextLink, type, onClick } = this.props;
    const { opened } = this.state;

    return (
      <div className="nav-menu-accordion">
        <div className={`collapser d-flex ${opened ? 'opened' : ''}`}>
          <div>
            {type === 'bold' ? (
              <strong>
                <Link {...nextLink}>
                  <a className="ignore-visited header-dropdown--link header-dropdown-mobile--link">
                    <span onClick={onClick} onKeyPress={onClick}>
                      {label}
                    </span>
                  </a>
                </Link>
              </strong>
            ) : (
              <Link {...nextLink}>
                <a className="ignore-visited header-dropdown--link header-dropdown-mobile--link">
                  <span onClick={onClick} onKeyPress={onClick}>
                    {label}
                  </span>
                </a>
              </Link>
            )}
          </div>
          <div>
            <a
              className={`icon-toggle header-dropdown-mobile--toggle ${opened ? 'toggled' : ''}`}
              onClick={this.toggle}
              title={opened ? `Close ${label}` : `Open ${label}`}
              tabIndex={0}
            >
              <img
                className={opened ? 'flipped' : ''}
                src={imageURL}
                alt={opened ? `Close ${label}` : `Open ${label}`}
                width={22}
                height={22}
              />
            </a>
          </div>
        </div>
        <div className={`contents ${opened ? '' : 'd-none'}`}>{children}</div>
      </div>
    );
  }
}

HeaderDropdownNavigationMobileAccordion.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  nextLink: PropTypes.shape({
    url: PropTypes.string,
    href: PropTypes.string,
    as: PropTypes.string,
  }).isRequired,
  opened: PropTypes.bool,
  onClick: PropTypes.func,
};

HeaderDropdownNavigationMobileAccordion.defaultProps = {
  opened: false,
  onClick: () => {},
};

export default HeaderDropdownNavigationMobileAccordion;
