import React from 'react';
import PropTypes from 'prop-types';

import Link from '../../../01_atoms/Link';
import TagText from '../../../01_atoms/Tag/TagText';

import LogoIcon from '../../../../public/static/icons/logos/logo.svg';
import LockIcon from '../../../../public/static/icons/outline_lock.svg';
import SSLIcon from '../../../../public/static/icons/ssl-icon.svg';
import RecaptchaIcon from '../../../../public/static/icons/recaptcha-icon.svg';
import HeaderMenuBasic from './HeaderMenuBasic';
import HeaderMenuMobileFull from './HeaderMenuMobileFull';
import HeaderMenuDesktopFull from './HeaderMenuDesktopFull';
import focusAndOpenKeyboard from '../../../../utils/focusAndOpenKeyboard';
import * as breakpoints from '../../../../utils/breakpoint';

import styles from './index.module.scss';

const HeaderMenu = ({
  device,
  display,
  isCheckoutHeader = false,
  toggled = {},
  onMenuToggle = () => {},
  homeNextLink = null,
  tags = null,
  ...props
}) => {
  const toggledSearch = toggled.search;
  const toggledRemains = toggled;

  const onToggleSearch = () => {
    Object.keys(toggledRemains).forEach((key) => {
      toggledRemains[key] = false;
    });

    onMenuToggle({
      ...toggledRemains,
      search: !toggledSearch,
    });

    // Autofocusing on search input
    if (!toggledSearch) {
      focusAndOpenKeyboard(
        breakpoints.isUp('md') ? 'redirect-input-search' : 'mobile-redirect-input-search',
      );
    }
  };

  const menus = {
    mobile: {
      basic: HeaderMenuBasic,
      full: HeaderMenuMobileFull,
    },
    desktop: {
      basic: HeaderMenuBasic,
      full: HeaderMenuDesktopFull,
    },
  };
  const Menu = menus[device][display];
  const MenuCore = (
    <>
      <div className="left">
        {homeNextLink && (
          <Link {...homeNextLink}>
            <a tabIndex={0}>
              <LogoIcon alt="Concern Worldwide" className="logo" aria-label="Concern Worldwide" />
            </a>
          </Link>
        )}

        {tags && (
          <div>
            {tags.map((tagProps) => (
              <TagText key={tagProps.label} {...tagProps} />
            ))}
          </div>
        )}
      </div>

      <div className="right">
        {!isCheckoutHeader && (
          <Menu
            searchToggleOnClick={onToggleSearch}
            searchToggleOnKeyDown={(e) => event.keyCode === 13 && onToggleSearch(e)}
            toggled={toggled}
            onMenuToggle={onMenuToggle}
            homeNextLink={homeNextLink}
            {...props}
          />
        )}
        {isCheckoutHeader && (
          <div className={styles['secure-connection']}>
            <LockIcon className={styles['lock-icon']} />
            <RecaptchaIcon className={styles['recaptcha-icon']} />
            <SSLIcon className={styles['ssl-icon']} />
          </div>
        )}
      </div>
    </>
  );

  return device === 'mobile' ? (
    <div className="clearfix">{MenuCore}</div>
  ) : (
    <div className="header-container">{MenuCore}</div>
  );
};

HeaderMenu.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape(TagText.propTypes)),
  device: PropTypes.oneOf(['mobile', 'desktop']).isRequired,
  display: PropTypes.oneOf(['basic', 'full']).isRequired,
  isCheckoutHeader: PropTypes.bool,
  onMenuToggle: PropTypes.func,
  toggled: PropTypes.shape(),
  homeNextLink: PropTypes.shape({
    url: PropTypes.string,
    href: PropTypes.string,
    as: PropTypes.string,
  }),
};

export default HeaderMenu;
