import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import SearchInput from '../../../01_atoms/SearchElements/SearchInput';

import styles from './index.module.scss';
import SuggestedSearches from '../../../01_atoms/SearchElements/SuggestedSearhes';
import SearchInputMobilePopup from '../../../01_atoms/SearchElements/SearchInputMobilePopup';

const HeaderDropdownSearch = ({
  onSearch,
  searchContents: { suggested, onGotoSearch: redirectToSearchPage },
  toggled,
  focusTopMenuItem = () => {},
}) => {
  const [value, setValue] = useState();
  const ref = useRef(null);

  const onGotoSearch = (formData) => {
    // Trigger on search which closes the search dropdown.
    onSearch();
    redirectToSearchPage(formData);
  };

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const reference = ref.current;

    // Keep focus inside opened menu during keyboard navigation.
    // https://css-tricks.com/a-css-approach-to-trap-focus-inside-of-an-element/#aa-the-css-trick-to-focus-trapping
    const transitionHandler = (event) => {
      if (focusTopMenuItem && event.target.classList.contains(styles['search-container'])) {
        focusTopMenuItem();
      }
    };

    reference.addEventListener('transitionend', transitionHandler);
    return () => {
      if (reference) {
        reference.removeEventListener('transitionend', transitionHandler);
      }
    };
  }, [ref, focusTopMenuItem]);

  return (
    <>
      <div className={`header-dropdown ${styles['desktop']} ${toggled ? '' : 'd-none'}`}>
        <div className={styles['search-container']} ref={ref}>
          <label className={styles['search-input-label']}>Search Concern website</label>
          <SearchInput
            submitSearchValue={() => onGotoSearch({ query: value })}
            setValue={setValue}
            value={value}
            inputId="redirect-input-search"
          />

          {suggested && suggested.items && suggested.items.length > 0 && (
            <SuggestedSearches
              title={suggested.title}
              suggestedSearches={suggested.items}
              goToSuggestedSearch={onSearch}
            />
          )}
        </div>
      </div>

      {toggled && (
        <div className={styles['mobile']}>
          <SearchInputMobilePopup
            submitSearchValue={() => onGotoSearch({ query: value })}
            setSearchValue={setValue}
            onClose={onSearch}
            value={value}
            suggestedSearchesTitle={suggested.title}
            suggestedSearches={suggested.items ? suggested.items : null}
            inputId="mobile-redirect-input-search"
            inputPlaceholder="Search Concern website"
            goToSuggestedSearch={onSearch}
          />
        </div>
      )}
    </>
  );
};

HeaderDropdownSearch.propTypes = {
  toggled: PropTypes.bool.isRequired,
  searchContents: PropTypes.shape({
    suggested: PropTypes.shape({
      title: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          nextLink: PropTypes.shape({
            href: PropTypes.string,
            as: PropTypes.string,
          }),
        }),
      ),
    }),
    onGotoSearch: PropTypes.func,
    inputValidators: PropTypes.arrayOf(PropTypes.func),
  }).isRequired,
  onSearch: PropTypes.func.isRequired,
  focusTopMenuItem: PropTypes.func,
};

export default HeaderDropdownSearch;
