import React from 'react';
import PropTypes from 'prop-types';

import Link from '../../../../../01_atoms/Link';
import HeaderDropdownNavigationMobileAccordion from './HeaderDropdownNavigationMobileAccordion';

let navMenuContentsMobileLevelCounter = 0;

const buildNavMenuContents = (obj, onClick) => {
  if (Array.isArray(obj)) {
    return obj.map((o) => buildNavMenuContents(o, onClick));
  }
  const { columns, contents, label, nextLink } = obj;

  navMenuContentsMobileLevelCounter += 1;

  if (columns !== undefined && columns.length) {
    return (
      <HeaderDropdownNavigationMobileAccordion
        key={`nav-menu-accordion-${label}`}
        label={label}
        nextLink={nextLink}
        onClick={onClick}
        type={navMenuContentsMobileLevelCounter > 1 ? '' : 'bold'}
      >
        {columns.map((o) => buildNavMenuContents(o, onClick))}
      </HeaderDropdownNavigationMobileAccordion>
    );
  }
  if (contents === undefined) {
    if (navMenuContentsMobileLevelCounter === 1) {
      return (
        <div className="collapser" key={`nav-menu-top-level-link-${label}`}>
          <strong>
            <Link {...nextLink}>
              <a className="header-dropdown--link header-dropdown-mobile--link">
                <span onClick={onClick} onKeyPress={onClick}>
                  {label}
                </span>
              </a>
            </Link>
          </strong>
        </div>
      );
    }

    return (
      <li className="icon-list" key={`nav-menu-link-${label}`}>
        <Link {...nextLink}>
          <a className="header-dropdown--link header-dropdown-mobile--link">
            <span onClick={onClick} onKeyPress={onClick}>
              {label}
            </span>
          </a>
        </Link>
      </li>
    );
  }

  return (
    <HeaderDropdownNavigationMobileAccordion
      key={`nav-menu-accordion-${label}`}
      label={label}
      nextLink={nextLink}
      onClick={onClick}
      type={navMenuContentsMobileLevelCounter > 1 ? '' : 'bold'}
    >
      {contents.map((o) => buildNavMenuContents(o, onClick))}
    </HeaderDropdownNavigationMobileAccordion>
  );
};

const HeaderDropdownNavigationMobile = ({ contents, onMenuToggle }) => (
  <>
    {contents.map((top) => {
      navMenuContentsMobileLevelCounter = 0;
      return buildNavMenuContents(top, onMenuToggle);
    })}
  </>
);

HeaderDropdownNavigationMobile.propTypes = {
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
    }),
  ).isRequired,
  onMenuToggle: PropTypes.func.isRequired,
};

export default HeaderDropdownNavigationMobile;
