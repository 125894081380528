import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../../01_atoms/Link';

import HomeIcon from '../../../../public/static/icons/home-icon.svg';

const HeaderMenuBasic = ({ homeNextLink = null }) => (
  <nav className="header-menu">
    {homeNextLink && (
      <Link {...homeNextLink}>
        <a aria-label="Homepage">
          <HomeIcon className="home" aria-hidden="true" />
        </a>
      </Link>
    )}
  </nav>
);

HeaderMenuBasic.propTypes = {
  homeNextLink: PropTypes.shape({
    url: PropTypes.string,
    href: PropTypes.string,
    as: PropTypes.string,
  }),
};

export default HeaderMenuBasic;
