import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import HeaderDropdownNavigationMobile from './HeaderDropdownNavigationMobile';
import HeaderDropdownNavigationDesktop from './HeaderDropdownNavigationDesktop';

const HeaderDropdownNavigation = ({
  device,
  toggled,
  onMenuToggle = () => {},
  focusTopMenuItem = () => {},
  ...props
}) => {
  const DropdownContents =
    device === 'mobile' ? HeaderDropdownNavigationMobile : HeaderDropdownNavigationDesktop;
  const displayClass = device === 'desktop' ? 'd-flex opened-desktop-menu' : '';

  // Keep focus inside opened menu during keyboard navigation.
  // https://css-tricks.com/a-css-approach-to-trap-focus-inside-of-an-element/#aa-the-css-trick-to-focus-trapping
  const transitionHandler = (event) => {
    if (event.target.classList.contains('opened-desktop-menu')) {
      focusTopMenuItem();
    }
  };

  const ref = useRef(null);
  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (device !== 'desktop') {
      return;
    }

    const reference = ref.current;
    reference.addEventListener('transitionend', transitionHandler);
    return () => {
      if (reference) {
        reference.removeEventListener('transitionend', transitionHandler);
      }
    };
  });

  return (
    <div className={`header-dropdown ${device} ${toggled ? displayClass : 'd-none'}`} ref={ref}>
      <DropdownContents {...props} onMenuToggle={() => onMenuToggle(false)} />
    </div>
  );
};

HeaderDropdownNavigation.propTypes = {
  toggled: PropTypes.bool.isRequired,
  device: PropTypes.string.isRequired,
  onMenuToggle: PropTypes.func,
  focusTopMenuItem: PropTypes.func,
};

export default HeaderDropdownNavigation;
