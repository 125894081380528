import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../../01_atoms/Link';
import Button from '../../../01_atoms/Button';

const imageURL = '/static/icons/navigation-chevron-down-desktop.svg';

class HeaderMenuDesktopFull extends React.Component {
  constructor(props) {
    super(props);

    this.menuToggleOnClick = this.menuToggleOnClick.bind(this);
    this.menuToggleOnKeyDown = this.menuToggleOnKeyDown.bind(this);
  }

  menuToggleOnClick(toggledNavRef) {
    const toggledRemains = { ...this.props.toggled };
    Object.keys(toggledRemains).forEach((key) => {
      if (key !== toggledNavRef) {
        toggledRemains[key] = false;
      }
    });
    toggledRemains[toggledNavRef] = !toggledRemains[toggledNavRef];
    this.props.onMenuToggle(toggledRemains);
  }

  menuToggleOnKeyDown(toggledNavRef) {
    const enter = 13;
    if (event.keyCode === enter) {
      const toggledRemains = { ...this.props.toggled };
      Object.keys(toggledRemains).forEach((key) => {
        if (key !== toggledNavRef) {
          toggledRemains[key] = false;
        }
      });
      toggledRemains[toggledNavRef] = !toggledRemains[toggledNavRef];
      this.props.onMenuToggle(toggledRemains);

      // Focus first link in the opened menu.
      setTimeout(() => {
        const element = this.props.dropdownRef.current.querySelector(
          '.opened-desktop-menu .header-dropdown-desktop--link',
        );
        if (element) {
          element.focus();
        }
      }, 100);
    }
  }

  render() {
    const { contents, toggled, topLinks, primaryCTA, searchToggleOnClick, searchToggleOnKeyDown } =
      this.props;
    return (
      <>
        {topLinks.length > 0 && (
          <nav className="header-toplinks d-flex justify-content-end" role="navigation">
            {topLinks.map((link) => (
              <mark key={`toplink-${link.label}`}>
                <Link {...link.nextLink}>
                  <a>{link.label}</a>
                </Link>
              </mark>
            ))}
          </nav>
        )}

        <div className="header-menu">
          <nav className="left" role="navigation" aria-label="Main menu">
            {contents.map((nav) => {
              const toggledNavRef = `navigation-${nav.slug}`;
              const toggledState = toggled[toggledNavRef];

              return nav.columns.length ? (
                <a
                  key={toggledNavRef}
                  className={`icon-toggle dropdown header-menu--link header-menu-desktop--link ${toggledState ? 'toggled' : ''}`}
                  onClick={() => this.menuToggleOnClick(toggledNavRef)}
                  onKeyDown={(event) => this.menuToggleOnKeyDown(toggledNavRef)}
                  tabIndex={0}
                >
                  {nav.label}
                  <img src={imageURL} alt="" width={16} height={9} />
                </a>
              ) : (
                <Link {...nav.nextLink}>
                  <a className="icon-toggle dropdown header-menu--link header-menu-desktop--link">
                    {nav.slug}
                  </a>
                </Link>
              );
            })}
          </nav>

          <div className="right">
            <a
              className={`icon-toggle search header-menu-search--link header-menu-desktop-search--link ${toggled.search ? 'toggled' : ''}`}
              onClick={searchToggleOnClick}
              onKeyDown={searchToggleOnKeyDown}
              title={toggled.search ? 'Close search' : 'Open search'}
              tabIndex={0}
              data-component="header-menu-search-link"
            >
              <img
                src={
                  toggled.search
                    ? '/static/icons/close-header-main-new.svg'
                    : '/static/icons/knowledge-hub-search.svg'
                }
                alt={toggled.search ? 'Close search' : 'Open search'}
                width={20}
                height={20}
              />
            </a>

            {primaryCTA && primaryCTA.label && primaryCTA.nextLink && (
              <Link {...primaryCTA.nextLink}>
                <Button
                  tag="a"
                  href={primaryCTA.nextLink.url}
                  className="header-menu--button header-menu-desktop--button"
                >
                  {primaryCTA.label}
                </Button>
              </Link>
            )}
          </div>
        </div>
      </>
    );
  }
}

HeaderMenuDesktopFull.propTypes = {
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      label: PropTypes.string,
      nextLink: PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
        as: PropTypes.string,
      }),
    }),
  ).isRequired,
  toggled: PropTypes.shape({
    navigation: PropTypes.bool,
    search: PropTypes.bool,
  }).isRequired,
  onMenuToggle: PropTypes.func,
  topLinks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      nextLink: PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
        as: PropTypes.string,
      }),
    }),
  ),
  primaryCTA: PropTypes.shape({
    label: PropTypes.string,
    nextLink: PropTypes.shape({
      url: PropTypes.string,
      href: PropTypes.string,
      as: PropTypes.string,
    }),
  }),
  dropdownRef: PropTypes.object.isRequired,
  searchToggleOnClick: PropTypes.func.isRequired,
  searchToggleOnKeyDown: PropTypes.func.isRequired,
};

HeaderMenuDesktopFull.defaultProps = {
  onMenuToggle: () => {},
  topLinks: [],
  primaryCTA: null,
};

export default HeaderMenuDesktopFull;
